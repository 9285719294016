import { useContext } from "react";
import { Welcome } from "./sample/Welcome";
import { TeamsFxContext } from "./Context";
import { useData } from "@microsoft/teamsfx-react";
import { app, Context } from "@microsoft/teams-js";
import config from "./sample/lib/config";

const showFunction = Boolean(config.apiName);

export default function Tab() {
  const { themeString } = useContext(TeamsFxContext);

  const { teamsUserCredential } = useContext(TeamsFxContext);

  console.log(teamsUserCredential);


  app.getContext().then((context) => {
      console.log(context);
  });

  const { loading, data, error } = useData(async () => {
    if (teamsUserCredential) {
      const userInfo = {
        tenantId: 'test',
        displayName: 'David'
      };
      return userInfo;
    }

    return {
      tenantId: 'test',
      displayName: 'David'
    };
  });

  console.log(loading, error, data);

  const displayName = (loading || error || !data) ? "": data!.displayName;
  const tenantId = (loading || error || !data) ? "": data!.tenantId;

  return (
    <div className={themeString === "default" ? "" : "dark"}>
      <pre style={{ display: 'none' }}>{JSON.stringify(data, null, 2)}</pre>
      <a href={"https://tt.fixpunkt.com/?tenantId=" + encodeURIComponent(tenantId) + "&displayName=" + encodeURIComponent(displayName)}>Click here.</a>
      {/*<Welcome showFunction={showFunction} />*/}
      <script>
        location.href = "https://tt.fixpunkt.com/?tenantId=" + encodeURIComponent(tenantId) + "&displayName=" + encodeURIComponent(displayName);
      </script>
    </div>
  );
}
